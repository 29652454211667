export default [
	{
		name: 'User onboarding',
		path: '/app/on-board',
		component: () => import('../views/app/OnBoarding.vue'),
		meta: {
			public: true,
			// requireNative: true,
			layout: 'fullScreen',
		},
	},
	{
		name: 'Welcome',
		path: '/app',
		component: () => import('../views/app/index.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Squad Details',
		path: '/app/my-squads',
		component: () => import('../views/app/MySquadDetails.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Profile',
		path: '/app/profile',
		component: () => import('../views/app/Profile.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Shoot Details',
		path: '/app/shoot',
		component: () => import('../views/app/MyShootDetails.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'View Shoot Scores',
		path: '/app/shoot/scores',
		component: () => import('../views/ShootViewer/ViewScores.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'View Shoot Payoffs',
		path: '/app/shoot/payoffs',
		component: () => import('../views/ShootViewer/ViewPayoffs.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'View Classification Changes',
		path: '/app/shoot/classification-changes',
		component: () => import('../views/ShootViewer/ViewClassAdjustments.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'View Shoot Winners',
		path: '/app/shoot/winners',
		component: () => import('../views/ShootViewer/ViewShootOffs.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'No Network',
		path: '/app/no-network',
		component: () => import('../views/app/NoNetwork.vue'),
		meta: {
			public: true,
			// fullWidth: false,
			// blockNative: true,
		},
	},
	{
		name: 'Other Shoots',
		path: '/app/my-other-shoots',
		component: () => import('../views/app/OtherShoots.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Find A Friend',
		path: '/app/find-shooter',
		component: () => import('../views/app/FindShooter.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Squad Me',
		path: '/app/shoot/squad',
		component: () => import('../views/app/MySquaddingChoices.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Pre Classify',
		path: '/app/preclassify/club/:clubId/shoot/:shootId',
		component: () => import('../views/app/PreClassify.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Select Options',
		path: '/app/cashier/options/event/:eventId',
		component: () => import('../views/app/SelectOptions.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
	{
		name: 'Pay for Event',
		path: '/app/cashier/pay/event/:eventId',
		component: () => import('../views/app/Pay.vue'),
		meta: {
			public: false,
			fullWidth: false,
		},
	},
];
