<template>
	<v-dialog
		v-model="show"
		:transition="topDown ? 'dialog-top-transition' : 'dialog-bottom-transition'"
		:persistent="persist"
		style="max-width: 1100px"
		:class="{
			'box-max-width': fullWidth,
		}"
	>
		<v-card class="limitMaxWidth" min-width="50vw" max-width="50vw">
			<v-toolbar v-show="!!header" :color="titleColor" style="width: 100%">
				<span class="text-h5 mx-2 px-3">
					{{ header }}
				</span>
			</v-toolbar>
			<v-card-text :style="header ? 'margin-top: 64px' : ''">
				<div class="text-body-1">
					<div
						v-if="bodyText.startsWith('html...')"
						v-html="bodyText.replace('html...', '')"
					/>
					<span v-else>
						{{ bodyText }}
					</span>
				</div>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn text @click="show = false">
					{{ buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapWritableState } from 'pinia';
import { alertStore } from '@/store/alert';
export default {
	computed: {
		...mapWritableState(alertStore, [
			'buttonText',
			'show',
			'bodyText',
			'header',
			'topDown',
			'titleColor',
			'persist',
			'p',
		]),
	},
	watch: {
		show(v) {
			//Reverse fly out direction from the fly-in direction
			if (v) {
				this.$nextTick(() => {
					this.topDown = !this.topDown;
				});
			} else {
				this.p.resolve(true);
			}
		},
	},
};
</script>

<style scoped>
@media (max-width: 600px) {
	.box-max-width {
		max-width: 99vw !important;
	}
}

@media (min-width: 601px) {
	.box-max-width {
		max-width: 50vw !important;
	}
}
</style>
